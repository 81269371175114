module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "Brand Mantra",
  shortName: "Brand Mantra",
  apiKey: "0f9baa55-b591-42c2-a4d4-69315a53bcb2",
  siteMetadata: {
    websiteTitle: "Brand Mantra",
    description: "Brand Mantra",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.37:4000",
  apiEndpointProd: "https://api.groweb.osperb.com",
  // apiEndpointProd: "https://api.chocochi.com",
  frontEndUrl: "https://groweb.osperb.com",
  tokenPrefix: "groweb_token",
}
