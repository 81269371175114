import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import moment from "moment"
import getDifferenceInDays from "components/hooks/useGetDifferenceInDays"
import { defualtProd } from "assets/imgs/logos"
import useWishlistAction from "components/hooks/useWishlistAction"
import storage from "util/localStorage"
import { useWindowSize } from "react-use"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useRemoveSpace } from "components/hooks/useRemoveSpace"
import LazyLoad from "react-lazyload"
import { Button } from "reactstrap"
import useCartActions from "components/hooks/useCartAction"
import { getLocalCart, showToasterAction } from "store/actions"
import { TOKEN_PREFIX } from "helpers/api_methods"
import { companyName } from "project.config"

const ProductCardOne = ({ product, className, button }) => {
  const dispatch = useDispatch()

  const { freeShippingThreshold, isDefaultStore } = useSelector(state => ({
    freeShippingThreshold:
      state.SiteSettings.siteSettings.shipping?.freeShippingThreshold,
    isDefaultStore: state.NearbyStore.store.isDefault,
  }))

  const token = localStorage.getItem(TOKEN_PREFIX)

  const user_favourite =
    storage.get("user_favourite") !== null && storage.get("user_favourite")

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product)

  const createdAt = moment(product?.createdAt).format("MM/DD/YYYY")
  const today = moment(new Date()).format("MM/DD/YYYY")

  const diffInDays = getDifferenceInDays(createdAt, today)

  const diff = Math.abs(oldPrice - price)

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : ""

  const { width } = useWindowSize()

  const handleCart = () => {
    const options = {
      product: product?._id,
      varient: product?.varients ? product?.varients[0]?._id : "",
      quantity: 1,
      operation: "add",
    }
    if (isDefaultStore === true) {
      dispatch(
        showToasterAction({
          data: {
            title: "Change your location",
            message: "this is default store, change your location",
          },
          type: "default",
        })
      )
    } else if (stock >= 1) {
      useCartActions(options, dispatch, { ...product, stock: stock })
      if (!token) {
        dispatch(getLocalCart())
      }
    }
  }

  return (
    <div className={`product-cart-wrap shoplist-child mb-30 ${className}`}>
      {/* {(product?.discountPercentage > 0 || discountPercentage > 0) && (
        <div className="product-card-offer-label">
          <p className="price-per">
            {product?.discountPercentage || discountPercentage}% off
          </p>
        </div>
      )} */}
      <div className="product-img-action-wrap shoplist flex-1 position-relative">
        <div className="product-img product-img-zoom">
          <Link to={`/products/${useRemoveSpace(language?.name)}`}>
            <img
              className="default-img"
              src={`${
                useCloudinary(product.images[0]?.url, "w_500") ?? defualtProd
              }`}
              alt="default-img"
              title="default-img"
              loading="eager"
            />
            {width >= 480 ? (
              <LazyLoad height={200}>
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "w_500"
                    ) ?? defualtProd
                  }
                  alt={`${companyName}-product`}
                  title="product"
                  loading="eager"
                />
              </LazyLoad>
            ) : (
              ""
            )}
          </Link>
        </div>

        {/* <div className="product-action-1">
          <a
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            onClick={e => handleWishlist(product?._id)}
          >
            {user_favourite?.length >= 1 &&
            user_favourite?.includes(product?._id) ? (
              <FaHeart className="text-danger" style={{ marginTop: "-6px" }} />
            ) : (
              <FaRegHeart
                className="text-black"
                style={{ marginTop: "-6px" }}
              />
            )}
          </a>
        </div> */}

        {/* <div
          className="product-badges product-badges-position 
          product-badges-mrg"
        >
          {stock <= 0 ? (
            <span className="text-white best curvy" style={{ zIndex: 5 }}>
              ! Out of stock
            </span>
          ) : (
            <>
              {product?.isFeatured && <span className="hot curvy">Hot</span>}
              {diffInDays >= 1 && diffInDays <= 2 && (
                <span className="new curvy">New</span>
              )}
            </>
          )}
        </div> */}
      </div>
      <div className="product-content-wrap pt-1 w-100">
        <h2 className="mb-0 mt-1">
          <Link
            to={`/products/${useRemoveSpace(language?.name)}`}
            className="font-normal"
            style={{ fontWeight: "normal" }}
          >
            <span>
              {language?.name?.slice(0, width >= 768 ? 80 : 30)}
              {language?.name?.length > (width >= 768 ? 80 : 30) && "..."}{" "}
              &nbsp;{" "}
              {currentVarient && (
                <span style={{ whiteSpace: "nowrap" }}>({currentVarient})</span>
              )}{" "}
            </span>
          </Link>
        </h2>

        {/* <div className="product-card-bottom mt-0 flex-column">
          <div className="product-price">
            <span
              className="font-head"
              style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
            >
              {currencyCode}
              {price}&nbsp;
            </span>
            {oldPrice && (
              <>
                <span
                  className="old-price font-normal m-0"
                  style={{ fontWeight: "500" }}
                >
                  {currencyCode}
                  {oldPrice}
                </span>
                &nbsp;
              </>
            )}
            {(product?.discountPercentage > 0 || discountPercentage > 0) && (
              <span className="price_percentage">
                {" "}
                {product?.discountPercentage || discountPercentage}% off
              </span>
            )}
            {(product?.discountPercentage > 0 || discountPercentage > 0) && (
              <span className="price-per">
                {product?.discountPercentage ?? discountPercentage}% off
              </span>
            )}
          </div>
          <div className="d-flex  justify-content-center align-items-center mt-2">
            <Button
              onClick={handleCart}
              disabled={stock <= 0}
              className={`product-card-bottom-button ${button}`}
            >
              <span>{stock <= 0 ? "Out of stock" : "Add To Cart"} </span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardOne)

ProductCardOne.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
  button: PropTypes.string,
}
